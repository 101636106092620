import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from '../actions/Common';
import { Api, getGetParams, getPostParams, getPutParams, getDeleteParams } from "../../@crema/utility/Utils";
import { 
  fetchInventoryListSucceeded, 
  submitInventorySucceeded, 
  updateInventorySucceeded, 
  deleteInventorySucceeded, 
  fetchInventoryByIdSucceeded,
  fetch_KVP_Trial_Succeeded,
  fetchTrialTreatmentSucceeded,
  submitPrintInventorySucceeded,
  fetchInventoryDetailsSucceeded,
  fetchInventoryListForSiteAndSponsorSucceeded,
  fetchBatchByTrialIdSucceeded,
  fetchProductCodeByTrialIdSucceeded,
  fetchUploadedFilesSucceeded,
} from "../actions/Inventory";

const baseUrlv1 = 'api/v1';

function fetchInventoryListByIdApi(id:string) {
  const url = `${baseUrlv1}/inventory/${id}`;
  return Api.fetch(url, getGetParams());
}

function fetchInventoryListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm, FromDate, ToDate, TrialId, alloted } = payload
  const url = `${baseUrlv1}/inventory?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}&FromDate=${FromDate}&ToDate=${ToDate}&TrialId=${TrialId}&alloted=${alloted}`;
  return Api.fetch(url, getGetParams());
}

function fetchInventoryListForSiteAndSponsorApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm, FromDate, ToDate, TrialId } = payload
  const url = `${baseUrlv1}/inventory/sitesponsor?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}&FromDate=${FromDate}&ToDate=${ToDate}&TrialId=${TrialId}`;
  return Api.fetch(url, getGetParams());
}

function submitInventoryApi (payload:any) {
  const formUpload = true;
  const url = `${baseUrlv1}/inventory`;
  return Api.fetch(url, getPostParams(payload, formUpload))
}

function updateInventoryApi(payload:any) {
  const url = `${baseUrlv1}/inventory`;;
  return Api.fetch(url, getPutParams(payload))
} 

function deleteInventoryApi(id:number) {
  const url = `${baseUrlv1}/inventory/${id}`;
  return Api.fetch(url, getDeleteParams())
}

function fetchKVPTrialApi(searchValue:string) {
  const url = searchValue.length > 0
    ? `${baseUrlv1}/trials/kvp?organisationId=${searchValue}`
    : `${baseUrlv1}/trials/kvp`;
  return Api.fetch(url, getGetParams());
}

function fetchTrialTreatmentApi(id: string) {
  const url = `${baseUrlv1}/trials/${id}/treatments/simple`;
  return Api.fetch(url, getGetParams());
}

function submitPrintInventorytApi(inventoryId:string) {
  const url = `${baseUrlv1}/inventory/${inventoryId}/printlabel`;
  return Api.fetch(url, getPostParams({inventoryId}));
}

function fetchInventoryDetailsApi(trialId: string, treatmentId: string, organisationId:string) {
  const url = `${baseUrlv1}/inventory/${trialId}/${treatmentId}/${organisationId}`;
  return Api.fetch(url, getGetParams());
}

function fetchBatchByTrialIdApi(id: string) {
  const url = `${baseUrlv1}/inventory/${id}/allbatches`;
  return Api.fetch(url, getGetParams());
}
function fetchProductCodeByTrialIdApi(id: string) {
  const url = `${baseUrlv1}/inventory/${id}/allproductcodes`;
  return Api.fetch(url, getGetParams());
}

function fetchUploadedFilesdApi(payload: any) {
  const url = `${baseUrlv1}/inventory/${payload}/allproductcodes`;
  return Api.fetch(url, getGetParams());
}

export function* fetchInventoryById({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchInventoryListByIdApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchInventoryByIdSucceeded(response))
    } else {
      let message: any;
      const { code} = response.error;
      
      if(code === 400 || code === 404) {
        message = "No Inventory found"
      }else {
        ({ message } = response.error);
      }
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchInventoryList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchInventoryListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchInventoryListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchInventoryListForSiteAndSponsor({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchInventoryListForSiteAndSponsorApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchInventoryListForSiteAndSponsorSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitInventory({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitInventoryApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitInventorySucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateInventory({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateInventoryApi, {...payload, id});
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updateInventorySucceeded(id, payload))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* deleteInventory({id}: any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(deleteInventoryApi, id);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(deleteInventorySucceeded(id))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchKVPTrial({ searchValue }:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchKVPTrialApi, searchValue);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetch_KVP_Trial_Succeeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchTrialTreatment({ id }:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchTrialTreatmentApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchTrialTreatmentSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitPrintInventory({ inventoryId }:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(submitPrintInventorytApi, inventoryId);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(submitPrintInventorySucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchInventoryDetails({ trialId, treatmentId, organisationId }:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchInventoryDetailsApi, trialId, treatmentId, organisationId);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchInventoryDetailsSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchBatchByTrialId({ id }:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchBatchByTrialIdApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchBatchByTrialIdSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchProductCodeByTrialId({ id }:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchProductCodeByTrialIdApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchProductCodeByTrialIdSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchUploadedFiles({ payload }:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchUploadedFilesdApi, payload);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchUploadedFilesSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}



export default [
  takeLatest('FETCH_INVENTORY_BY_ID' ,fetchInventoryById),
  takeLatest('FETCH_INVENTORY_LIST' ,fetchInventoryList),
  takeLatest('FETCH_INVENTORY_LIST_FOR_SITE_AND_SPONSOR' ,fetchInventoryListForSiteAndSponsor),
  takeLatest('SUBMIT_INVENTORY' ,submitInventory),
  takeLatest('UPDATE_INVENTORY' ,updateInventory),
  takeLatest('DELETE_INVENTORY' ,deleteInventory),
  takeLatest('FETCH_KVP_TRIAL' ,fetchKVPTrial),
  takeLatest('FETCH_TRIAL_TREATMENT' ,fetchTrialTreatment),
  takeLatest('SUBMIT_PRINT_INVENTORY' ,submitPrintInventory),
  takeLatest('FETCH_INVENTORY_DETAILS' ,fetchInventoryDetails),
  takeLatest('FETCH_BATCH_BY_TRIALID' ,fetchBatchByTrialId),
  takeLatest('FETCH_UPLOADED_FILES' ,fetchProductCodeByTrialId),
  takeLatest('FETCH_UPLOADED_FILES' ,fetchProductCodeByTrialId),
]
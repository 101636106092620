export enum ThemeStyle {
  MODERN = 'modern',
  STANDARD = 'standard',
}

export enum ThemeStyleRadius {
  MODERN = 30,
  STANDARD = 2,
}

export enum ThemeMode {
  LIGHT = 'light',
  SEMI_DARK = 'semi-dark',
  DARK = 'dark',
}

export enum LayoutType {
  FULL_WIDTH = 'full-width',
  BOXED = 'boxed',
}

export enum NavStyle {
  DEFAULT = 'default',
  MINI = 'mini',
  STANDARD = 'standard',
  HEADER_USER = 'user-header',
  HEADER_USER_MINI = 'user-mini-header',
  DRAWER = 'drawer',
  BIT_BUCKET = 'bit-bucket',
  H_DEFAULT = 'h-default',
  HOR_LIGHT_NAV = 'hor-light-nav',
  HOR_DARK_LAYOUT = 'hor-dark-layout',
}

export enum FooterType {
  FIXED = 'fixed',
  FLUID = 'fluid',
}

export enum HeaderType {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum RouteTransition {
  NONE = 'none',
  FADE = 'fade',
  SLIDE_LEFT = 'slideLeft',
  SLIDE_RIGHT = 'slideRight',
  SLIDE_UP = 'slideUp',
  SLIDE_DOWN = 'slideDown',
}

export enum Fonts {
  LIGHT = 'Gilroy-Light',
  REGULAR = 'Gilroy-Regular',
  MEDIUM = 'Gilroy-Medium',
  BOLD = 'Gilroy-Bold',
  EXTRA_BOLD = 'Gilroy-ExtraBold',
}

export enum Types {
  // Machship = 1,
  Manual = 2
}

export enum SponsorTypes {
  Sponsor = 1,
  "Sponsor Representative" = 2
}

export enum UserTypes {
  Pharmacy = 2,
  Site = 3,
  Sponsor = 4,
  Admin = 1,
  Warehouse = 5,
}

export enum Pharmacy {
  Pharmacists = 1,
  "Pharmacy Assistants" = 2
}

export enum Site {
  "Site" = 1
}

export enum Sites {
  "Principal Investigators" = 1,
  "Study Coordinators" = 2
}

export enum AdminStats {
  "Patients" = 1,
  "Trials" = 2,
  "Dispenses" = 3,
  "Sites" = 4,
  "Sponsors" = 5
}

export enum Sponsor {
  Sponsor = 1,
  "Sponsor Representative" = 2
}

export enum Admin {
  Admin = 1,
  Monitor = 2,
  "Quality Associate QA" = 3
}

export enum Warehouse {
  Warehouse = 1,
  "Warehouse Assistant" = 2
}

export enum TrialProgress {
  Details = 1,
  Pharmacy = 2,
  Drugs = 3,
  Treatments = 4,
  TreatmentArms = 5,
  Repeats = 6,
  Labels = 7,
  Consultations = 8,
  ReturnsInventory = 9,
  Users = 10,
  EmergencyUnblinding = 11,
  Stratification = 12,
  RandomisationList = 13,
  Shipping = 14,
  Pricing = 15,
  FileLibrary = 16
}

export enum TrialType {
  "Open Label" = 1,
  "Blinded" = 2
}

export enum BottleIdentifier{
  "Randomisation Number" = 1,
  "Kit Number" = 2,
  "Bottle Number" = 3,
  "No Number" = 4
}

export enum Unit{
  "Bottle" = 1,
  "Ampoule" = 2,
  "Vial" = 3,
  "Kit" = 4,
  "Pack" = 5,
  "Carton" = 6,
  "Roll" = 7,
  "Jar" = 8,
  "Bag" = 9,
  "Other" = 10
}

export enum TrialStatus {
  Draft = 1,
  Pending = 2,
  Approved = 3,
  Rejected = 4,
  "Pending Archive" = 5,
  Archived = 6,
  Ended = 7
}

export enum Frequency {
  // "1 day (for internal test purpose)" = 1,
  "30 days (monthly)" = 30,
  "90 days (quarterly)" = 90,
  "180 days (half yearly)" = 190,
  "360 days (yearly)" = 360
}

export enum RandomisationFileType {
  "One File for ALL Sites" = 1, // Global
  "One File Per Site" = 2 // PerSite
}

export enum StorageConditions { // &#176;
  "Store below -20&deg;C" = 1, //BelowMinus20Celcius
  "Store between 2&deg;C - 8&deg;C" = 2, //2To8Celcius
  "Store between 15&deg;C - 25&deg;C" = 3, //15To25Celcius
  "Store below 25&deg;C" = 4, //Below25Celcius
  "Store below 30&deg;C" = 5 //Below30Celcius
}

export enum ShippingTemperature {
  "Store below -20&deg;C" = 1, 
  "Store between 2&deg;C - 8&deg;C" = 2,
  "Store between 15&deg;C - 25&deg;C" = 3,
  "Uncontrolled Ambient" = 4
}


export enum DrugType {
  Active = 1,
  Placebo = 2,
  Blinded = 3
}

export enum TreatmentType {
  "Run-in" = 2,
  "On-study" = 1,
  "Open Label" = 3
}

export enum QATypes {
  "Drugs" = 1,
  "Treatments" = 2,
  "Treatments Arms" = 3,
  "Trial Details" = 4,
  "Pricing" = 5,
  "Counselling Scripts" = 6,
  "Label templates in Bartender" = 7,
  "Sites Pharmacies" = 8,
  "Repeats" = 9,
  // "Treatment Labels" = 10,
  "Returns" = 10,
  "Emergency Unblinding" = 11,
  "Stratification" = 12,
  "Shipping" = 13,
  "Users" = 14,
  "Files" = 15,
  "Randomisation List"=16
}

export enum QAActions {
  "Create" = 1,
  "Update" = 2,
  "Delete" = 3
}

export enum ObjectTypes {
  "TrialChecklist" = 1,
  "Trial" = 2,
  "Prescription" = 3,
  "Inventory" = 4,
  "Stocktake" = 5,
  "AdjustRandomisation" = 6,
  "BulkOrder" = 7,
  "TrialPricing" = 8,
  "TrialConsultation" = 9,
  "TrialLabel" = 10,
  "TrialTreatmentArm" = 11,
  "TrialTreatment" = 12,
  "TrialDrug" = 13,
  "TrialSites & Distributors" = 14,
  "TrialRepeats" = 15,
  "TrialTreatmentLabel" = 16,
  "TrialReturns" = 17,
  "TrialUnblinding" = 18,
  "TrialStratification" = 19,
  "TrialShipping" = 20,
  "TrialSiteUsers" = 21,
  "TrialFiles" = 22,
  "BottleNumber" = 23,
  "KitNumber" = 24,
  "Randomisation" = 25,
  "TrialRandomisation" = 26
}

export enum PrescriptionStatus {
  "To Dispense" = 1,
  "Dispensed" = 2,
  "Dispensed Approved" = 3,
  "Packed" = 4,
  "Shipped" = 5,
  "Received" = 6,
  "Cancelled" = 7,
  "Re-Dispense" = 8,
  // "Rejected" = 9
}

export enum PickStatus {
  "To Pick" = 1,
  "Picked" = 2,
  "Picked Approved" = 3,
  "Packed" = 4,
  "Shipped" = 5,
  "Received" = 6,
  "Cancelled" = 7,
  "Re-Pick" = 8,
  // "Rejected" = 9
}

export enum ShippingOptions {
  "Patient Delivery" = 1,
  "Site Pickup" = 2,
  "Pharmacy Pickup" = 3
}

export enum Months {
  January = 1,
  February = 2, 
  March = 3, 
  April = 4, 
  May = 5, 
  June = 6, 
  July = 7, 
  August = 8, 
  September = 9, 
  October = 10, 
  November = 11, 
  December = 12
}

export enum DistributorType {
  Pharmacy = 1,
  Warehouse = 2
}

export enum CommentType {
  Prescription = 1,
  Consignment = 2,
  BulkOrder = 3,
  SiteShipping = 4
}


// TODO include this later on Azure config settings
export enum TinyMCE {
  API = 'yt48xae96gsy5oopn6gcjpebafx8ht6sjyxq1g1dhfus9iqb'
}

// TODO include this later on Azure config settings
export enum GooglePlace {
  API = 'AIzaSyAgj-S2cVFOsZmadqmh9E1jFFhNlsY7iOU'
}

export enum AlertType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR =  'error'
}

export enum SiteShippingStatus {
  "To Ship" = 1,
  "Shipped" = 2,
  "Received" = 3
}

// Comment Types
export enum CommentType {
  SYSTEM_GENERATED = 1,
  USER = 2
}

export enum ReturnInventoryType {
  External = 1,
  Internal = 2
}

export enum ReturnInventoryDestructionType {
  Destroyed = 1,
  Returned = 2
}

export enum BaseUrl {
  API = 'http://syntro-dev-api.azurewebsites.net/'
}

